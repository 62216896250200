  <template>
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title">Email</div>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body p-2">
        <div class="px-4 py-3">
          <OnboardingTitle>
            The following attachments will be sent to {{ clientName }}
            <button
              type="button"
              class="btn btn-sm btn-outline-primary ml-2"
              v-on:click="viewConsentEmail()"
            >
              <span>View Email</span>
            </button>
          </OnboardingTitle>

          <div class="list-group list-group-flush">
            <button
              class="list-group-item list-group-item-action"
              v-for="file in attachments"
              v-on:click="viewFile(file)"
            >
              <i class="fas fa-file-download mr-1"></i>
              {{ file }}
            </button>
          </div>
        </div>
      </div>
      <a ref="download" class="d-none"></a>

      <div class="modal-footer pr-3">
        <div>
          <button
            type="button"
            class="btn btn-sm modal-button btn-outline-danger"
            v-on:click="back()"
          >
            <span>Back</span>
          </button>
          <button
            type="button"
            class="btn btn-sm modal-button btn-primary ml-2"
            v-on:click="send()"
          >
            <span>Send</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.email-body {
  color: #212529;
}
.modal-title {
  height: 24px;
  display: flex;
  align-items: center;
  margin-left: 16px;

  font-weight: bold;
  font-size: 17px;
  line-height: 20px;
  color: #616466;
}
.modal-button {
  width: 80px;
}

.modal-body {
  min-height: 50vh;
}
.modal-footer {
  justify-content: center;
}
</style>

<script>
import { Modal, Api } from "../../factory";
import { alert, session } from "../../service";
import IconButton from "../../component/icon-button.vue";

import OnboardingCheckbox from "./components/onboarding-checkbox.vue";
import OnboardingLabel from "./components/onboarding-label.vue";
import OnboardingTitle from "./components/onboarding-title.vue";

export default {
  props: {
    data: {
      methods: {
        send: () => "",
      },
      clientOnboardingData: {
        type: Object
      },
      isQtr: Boolean
    }
  },
  data() {
    return {
      clientName: "",
      attachments: [],
    }
  },
  created() {

    if (this?.data?.clientOnboardingData?.household?.name?.value) {
      this.clientName = this.data.clientOnboardingData.household.name.value
      this.clientName = this.data.clientOnboardingData.contacts.firstName.value
        + ' ' + this.data.clientOnboardingData.contacts.lastName.value
      this.attachments = this.data.clientOnboardingData.disclosureNotices.options
        .filter(({ checked }) => checked)
        .map(({ label }) => label)
        .filter(label => label != "Email Consent")
    }
  },
  methods: {
    back() {
      if (this.data.methods.back) {
        this.data.methods.back()
      }
    },
    send() {
      this.data.methods.send()
    },
    viewConsentEmail() {
      const state =  session.get(`state`);
      Modal.open("EmailConsent", {
        // firm
        isQtr: state.firm._id === '674487c2593d5d5343d539c3',
        methods: {
          back: () => {
            Modal.open("OnboardConfirm", this.data)
          },
        },
      })
    },
    async viewFile(file) {
      try {
        let data;

        if (file.toLowerCase().endsWith(".pdf")) {
          const { data: name } = await Api.get(`files?name=${file}`);
          data = name;
        } else {
          const { data: slot } = await Api.get(`files?slot=${file}`);
          data = slot;
        }

        var el = this.$refs.download;
        el.setAttribute(`href`, data.dataURI);
        el.setAttribute(`download`, data.name);
        el.click();
        el.removeAttribute(`href`);
        el.removeAttribute(`download`);
      }
      catch (e) {
        console.error(e);
        alert.error(e.message);
      }
    }
  },
  components: {
    OnboardingTitle,
    OnboardingLabel,
    OnboardingCheckbox,
    IconButton,
  }
};
</script>
