<template>
  <div class="modal-dialog modal-dialog-centered modal-xl"
       role="document">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title">
          <i class="fas fa-file-alt mr-2"></i>
          <span class="font-weight-bold"
                v-text="invoice.text(`modal`)"></span>
        </p>
        <button type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body p-2">
        <template v-if="invoice.ready">
          <scroll-area class="px-4 py-3">
            <p class="font-weight-bold">Invoice</p>
            <p v-if="invoice.quarterbill">
              <i>this firm is billing with quarterly bill function</i>
            </p>
            <p v-if="invoice.monthlyBill">
              <i>This firm is billing with monthly bill function</i>
            </p>
            <div class="form-row">
               <div class="col-3">
                 <div class="form-group">
                   <date-picker :clearable="false" class="date-picker" format="MMM D, YYYY" v-model="invoice.date"></date-picker>
                   <small class="form-text text-muted">Invoice Date</small>
                 </div>
               </div>
               <div class="col-3">
                 <select v-model="invoice.type"
                         class="form-control form-control-sm text-capitalize"
                         :disabled="invoice.disabled(`type`)"
                         v-on:change="invoice.change(`type`)">
                   <option class="text-capitalize"
                           v-for="(o, i) in invoice.options(`type`)"
                           :key="i">
                     <span class="text-capitalize"
                           v-text="o"></span>
                   </option>
                 </select>
                 <small class="form-text text-muted">Invoice Type</small>
               </div>
            </div>
            <p class="font-weight-bold">Values used to calculate fees</p>
            <template v-if="invoice.isAdvance">
              <div class="form-row">
                <div class="form-group col-6" v-if="invoice.isLatest">
                  <date-picker :clearable="false" :disabled-date="disableWeekends" 
                  class="date-picker" format="MMM D, YYYY" 
                  v-model="invoice.period.advance.latest"></date-picker>
                  <small class="form-text text-muted">Latest</small>
                </div>
                <div class="form-group col-6" v-else>
                  <date-picker :clearable="false" :disabled-date="disableWeekends" 
                  class="date-picker" format="MMM D, YYYY" 
                  v-model="invoice.period.advance.adb" range></date-picker>
                  <small class="form-text text-muted">Average Daily Balance</small>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="form-row">
                <div class="form-group col-6" v-if="invoice.isLatest">
                  <date-picker :clearable="false" :disabled-date="disableWeekends" 
                  class="date-picker"  format="MMM D, YYYY" 
                  :default-value="new Date()" 
                  v-model="invoice.period.arrears.latest"></date-picker>
                  <small class="form-text text-muted">Latest</small>
                </div>
                <div class="form-group col-6" v-else>
                  <date-picker :clearable="false" :disabled-date="disableWeekends" 
                  class="date-picker" format="MMM D, YYYY" 
                  v-model="invoice.period.arrears.adb" range></date-picker>
                  <small class="form-text text-muted">Average Daily Balance</small>
                </div>
              </div>
            </template>
            <div class="form-row">
                <div class="form-group col-6">
                  <p class="font-weight-bold">Fee applied to the following period</p>
                  <date-picker :clearable="false"  
                  class="date-picker" format="MMM D, YYYY" 
                  v-model="invoice.period.range" range></date-picker>
                  <small class="form-text text-muted">Apply for Period</small>
                </div>
            </div>
            <hr />
            <div class="d-flex">
              <div>
                <p class="font-weight-bold">Accounts Due</p>
              </div>
              <div class="flex-grow-1 text-right">
                <span class="mr-2">Obfuscate account numbers</span>
                <checkbox :o="invoice.show"
                          p="numbers"
                          title="Toggle obfuscate account numbers"></checkbox>
              </div>
              <div class="text-right ml-4">
                <span class="mr-2">Show all accounts</span>
                <checkbox :o="invoice.show.all"
                          p="due"
                          title="Toggle all accounts"></checkbox>
              </div>
            </div>
            <table class="table table-sm accounts mb-5">
              <thead>
                <tr>
                  <th>
                    <span>Number</span>
                  </th>
                  <th>
                    <span>Name</span>
                  </th>
                  <th>
                    <span>Household</span>
                  </th>
                  <th class="text-right">
                    <span>Billing</span>
                  </th>
                  <th class="text-right">
                    <span>Amount</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(o, i) in invoice.accounts(`due`)"
                    :key="i">
                  <td>
                    <span v-text="o.number"></span>
                  </td>
                  <td>
                    <span v-text="o.name"></span>
                  </td>
                  <td>
                    <span v-text="o.household"></span>
                  </td>
                  <td class="text-right">
                    <span v-text="o.billing"></span>
                  </td>
                  <td class="text-right">
                    <span v-text="invoice.text(`due`, o)"></span>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="3"></td>
                  <td class="text-right">
                    <span class="font-weight-bold">Total</span>
                  </td>
                  <td class="text-right">
                    <span class="total"
                          v-text="invoice.text(`total`, `due`)"></span>
                  </td>
                </tr>
              </tfoot>
            </table>
            <hr />
            <div class="d-flex">
              <div>
                <p class="font-weight-bold">Account Fees</p>
              </div>
              <div class="flex-grow-1 text-right">
                <span class="mr-2">Show all fees</span>
                <checkbox :o="invoice.show.all"
                          p="fees"
                          title="Toggle all fees"></checkbox>
              </div>
            </div>
            <table class="table table-sm accounts mb-0">
              <thead>
                <tr>
                  <th>
                    <span>Number</span>
                  </th>
                  <th>
                    <span>Name</span>
                  </th>
                  <th>
                    <span>Schedule</span>
                  </th>
                  <th class="text-right">
                    <span>Value</span>
                  </th>
                  <th class="text-right">
                    <span>Fee</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(o, i) in invoice.accounts(`fees`)"
                    :key="i">
                  <td>
                    <span v-text="o.number"></span>
                  </td>
                  <td>
                    <span v-text="o.name"></span>
                    <small v-if="o.proratedDays" class="text-muted small">(pro-rated for {{ o.proratedDays }} days)</small>
                  </td>
                  <td>
                    <span v-text="o.schedule"></span>
                  </td>
                  <td class="text-right">
                    <span v-text="invoice.text(`value`, o)"></span>
                  </td>
                  <td class="text-right">
                    <span v-text="invoice.text(`fee`, o)"></span>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="2"></td>
                  <td class="text-right">
                    <span class="font-weight-bold">Total</span>
                  </td>
                  <td class="text-right">
                    <span class="total"
                          v-text="invoice.text(`total`, `account-values`)"></span>
                  </td>
                  <td class="text-right">
                    <span class="total"
                          v-text="invoice.text(`total`, `account-fees`)"></span>
                  </td>
                </tr>
              </tfoot>
            </table>
            <p class="text-right pr-3">
              <small class="text-muted"
                     v-text="invoice.text(`owing`)"></small>
            </p>
            <hr />
            <div style="padding-bottom: 0.5rem">
              <div class="d-flex">
                <span class="font-weight-bold">Schedules</span>
                <div class="flex-grow-1 text-right">
                  <span class="mr-2">Show all tiers</span>
                  <checkbox :o="invoice.show.all"
                            p="tiers"
                            title="Toggle all tiers"></checkbox>
                </div>
              </div>

              <span class="text-secondary"
                    v-if="invoice.group">
                <small>
                  These schedules are applied in a group. Exceptions are 
                  shared among members of a group.
                </small>
              </span>
            </div>
            <div v-for="(o, index) in invoice.schedules"
                 :key="index">
              <hr class="mt-0" />
              <p class="schedule"
                 v-text="invoice.text(`schedule`, o, index)"></p>
              <table class="table table-sm schedule mb-4">
                <thead>
                  <tr>
                    <th>
                      <span>Tier</span>
                    </th>
                    <th class="text-right">
                      <span>Rate</span>
                    </th>
                    <th v-if="invoice.group"
                        class="text-right">
                      <span>Applied group rate</span>
                    </th>
                    <th></th>
                    <th class="text-right">
                      <span>Value</span>
                    </th>
                    <th class="text-right">
                      <span>Fee</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(split, i) of invoice.tiers(o)"
                      :key="i">
                    <td>
                      <span v-text="invoice.text(`tier`, o, i)"></span>
                    </td>
                    <td class="text-right">
                      <span v-text="invoice.text(`rate`, o, i)"></span>
                    </td>
                    <td v-if="invoice.group"
                        class="text-right">
                      <span v-text="invoice.text(`group-rate`, o, i)"></span>
                    </td>
                    <td></td>
                    <td class="text-right">
                      <span v-text="invoice.text(`split`, o, i)"></span>
                    </td>
                    <td class="text-right">
                      <span v-text="invoice.text(`fee`, o, i)"></span>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="2"></td>

                    <td v-if="invoice.group"></td>

                    <td class="text-right">
                      <span class="font-weight-bold">Total</span>
                    </td>
                    <td class="text-right">
                      <span class="total"
                            v-text="invoice.text(`total`, `schedule-splits`, o)"></span>
                    </td>
                    <td class="text-right">
                      <span class="total"
                            v-text="invoice.text(`total`, `schedule-fees`, o)"></span>
                    </td>
                  </tr>
                </tfoot>
              </table>
              <template v-if="o.owing">
                <p class="text-right">
                  <small class="text-muted"
                         v-text="invoice.text(`owing`, o)"></small>
                </p>
              </template>
            </div>

            <p class="text-right pr-3">
              <small class="text-muted"></small>
            </p>
            <hr />

            <div v-if="invoice.lineItems.length"
                 class="mb-4">
              <p class="font-weight-bold">Line Items</p>
              <table class="table table-sm">
                <thead>
                  <tr>
                    <th>
                      <span>Description</span>
                    </th>
                    <th>
                      <span>Line Item Period</span>
                    </th>
                    <th>
                      <span>Account</span>
                    </th>
                    <th>
                      <span>Timing</span>
                    </th>
                    <th>
                      <span>Amount</span>
                    </th>
                    <th class="text-center">
                      <span>Due</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <template>
                    <tr  v-for="(o, i) in invoice.lineItems" 
                    :key="i" v-show="invoice.showLineItem(o)">
                      <td>
                        <span v-text="o.description"></span>
                      </td>
                      <td>
                        <span>{{ invoice.text(`lineitem-period`, o) }}</span>
                      </td>
                      <td>
                        <span v-text="invoice.text(`account`, o)"></span>
                      </td>
                      <td>
                        <span v-text="o.timing"></span>
                      </td>
                      <td>
                        <span v-text="invoice.text(`amount`, o)"></span>
                      </td>
                      <td  class="text-center">
                        <span v-if="o.timing.toLowerCase() == `annual`">
                        {{ invoice.text(`amount-due`, o) }}</span>
                        <span v-else>N/A</span>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
            <p class="text-right pr-3"
               v-if="invoice.lineItems.length">
              <small class="text-muted"></small>
            </p>
            <hr v-if="invoice.lineItems.length" />

            <!-- <p class="font-weight-bold">Line Items</p>
            <table class="table table-sm line-items mb-4">
              <thead>
                <tr>
                  <th>
                    <span>Account</span>
                  </th>
                  <th class="text-right">
                    <span class="mr-1">Value</span>
                  </th>
                  <th>
                    <span class="ml-1">Comment</span>
                  </th>
                  <th class="text-right">
                    <i
                      class="fas fa-plus-circle"
                      :title="invoice.title(`add`, `line-item`)"
                      v-on:click="invoice.click(`add`, `line-item`)"
                    ></i>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, idx) in invoice.line.items" :key="idx">
                  <td>
                    <select
                      v-model="item.account"
                      class="form-control form-control-sm"
                    >
                      <option
                        v-for="(o, i) in invoice.accounts(`line-items`)"
                        :key="i"
                      >
                        <span v-text="o"></span>
                      </option>
                    </select>
                  </td>
                  <td class="text-right">
                    <div class="input-group input-group-sm">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <b class="px-1">$</b>
                        </span>
                      </div>
                      <input
                        v-model="item.value"
                        class="form-control form-control-sm text-right"
                        placeholder="0"
                        v-on:focus="invoice.focus(`line-item`, item, `value`)"
                        v-on:blur="invoice.blur(`line-item`, item, `value`)"
                      />
                    </div>
                  </td>
                  <td>
                    <input
                      v-model="item.comment"
                      class="form-control form-control-sm"
                      placeholder="Comment"
                    />
                  </td>
                  <td class="text-right">
                    <i
                      class="fas fa-minus-circle"
                      :title="invoice.title(`remove`, `line-item`, idx)"
                      v-on:click="invoice.click(`remove`, `line-item`)"
                    ></i>
                  </td>
                </tr>
              </tbody>
            </table>-->

            <template v-if="invoice.lastInvoice">
              <div>
                <div class="d-flex">
                  <div>
                    <p class="font-weight-bold">Adjustments</p>
                  </div>
                </div>

                <hr class="mt-0" />
                <p class="schedule">
                  {{ invoice.display_date(invoice.lastInvoice.period.start) }} -
                  {{ invoice.display_date(invoice.lastInvoice.period.end) }}
                </p>
              </div>
            </template>
            <template v-else>
              <div></div>
            </template>
            <template v-if="invoice.accountsChanges">
              <div>
                <hr class="mt-0" />
                <p class="schedule">Accounts Adjustments</p>
                <table class="table table-sm accounts mb-4">
                  <thead>
                    <tr>
                      <th>
                        <span>Number</span>
                      </th>
                      <th>
                        <span>Name</span>
                      </th>
                      <th>
                        <span>Schedule</span>
                      </th>
                      <th class="text-right">
                        <span>Change</span>
                      </th>
                      <th class="text-right">
                        <span>Adjustment</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(ac, i) of invoice.accountsChanges"
                        :key="i">
                      <td>
                        <span v-text="ac.number"></span>
                      </td>
                      <td>
                        <span v-text="invoice.text(`account-name`, ac)"></span>
                      </td>
                      <td>
                        <span v-text="invoice.text(`schedule-name`, ac)"></span>
                      </td>
                      <td class="text-right no-wrap">
                        <span v-text="ac.change_f"></span>
                      </td>
                      <td class="text-right no-wrap">
                        <span v-text="ac.adjustment_f"></span>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="2"></td>
                      <td class="text-right">
                        <span class="font-weight-bold">Total</span>
                      </td>
                      <td class="text-right no-wrap">
                        <span class="total"
                              v-text="
                                invoice.text(`total`, `accounts-changes-change`)
                              "></span>
                      </td>
                      <td class="text-right no-wrap">
                        <span class="total"
                              v-text="
                                invoice.text(`total`, `accounts-changes-adjustment`)
                              "></span>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </template>
            <template v-else>
              <div></div>
            </template>

            <template v-if="invoice.schedulesChanges">
              <div>
                <hr class="mt-0" />
                <p class="schedule">Schedules changes</p>
                <table class="table table-sm mb-4">
                  <thead>
                    <tr>
                      <th>
                        <span>Schedule</span>
                      </th>
                      <th class="text-right"
                          style="width: 1px">
                        <span>Change</span>
                      </th>
                      <th class="text-right">
                        <span>Adjustment</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(sc, i) of invoice.schedulesChanges"
                        :key="i">
                      <td>
                        <span v-text="sc.name"></span>
                      </td>
                      <td class="text-right no-wrap">
                        <span v-text="sc.change_f"></span>
                      </td>
                      <td class="text-right no-wrap">
                        <span v-text="sc.adjustment_f"></span>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td class="text-right">
                        <span class="font-weight-bold">Total</span>
                      </td>
                      <td class="text-right no-wrap">
                        <span class="total"
                              v-text="
                                invoice.text(`total`, `schedules-changes-change`)
                              "></span>
                      </td>
                      <td class="text-right no-wrap">
                        <span class="total"
                              v-text="
                                invoice.text(
                                  `total`,
                                  `schedules-changes-adjustment`
                                )
                              "></span>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </template>
            <template v-else>
              <div></div>
            </template>

            <div v-if="invoice.hasTransactions">
              <rebated-transactions :transactions="invoice.transactions"
                                    :obfuscateNumbers="invoice.show.numbers"></rebated-transactions>
            </div>
            <div v-else></div>


            <div class="d-flex">
              <div>
                <p class="font-weight-bold">Grand total</p>
              </div>
            </div>
            <table class="table table-sm mb-4">
              <thead>
                <tr>
                  <th style="width: 8%; white-space: nowrap">
                    <span>Account</span>
                  </th>
                  <th>
                    <span>Name</span>
                  </th>
                  <th></th>
                  <th class="text-right">
                    <span>Amount</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(ac, i) of invoice.accounts(`grandtotal`)"
                    :key="i">
                  <td>
                    <span v-text="ac.number"></span>
                  </td>
                  <td>
                    <span v-text="ac.name"></span>
                  </td>
                  <td :set="nTotal = invoice.getAccountGrandTotal(ac)">
                    <span v-if="nTotal < 0">
                      <em>(Carryover {{value(nTotal * -1) }})</em>
                    </span>
                  </td>
                  <td class="text-right">
                    <span v-text="invoice.text(`account-grand-total`, ac)"></span>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td></td>
                  <td></td>
                  <td class="text-right">
                   <span class="font-weight-bold">Total</span>
                  </td>
                  <td class="text-right no-wrap">
                    <span class="total"
                          v-text="invoice.text(`total`, `account-grand-total`)"></span>
                  </td>
                </tr>
              </tfoot>
            </table>
          </scroll-area>
        </template>
        <template v-else>
          <loading></loading>
        </template>
      </div>
      <div class="modal-footer pr-3">
        <div class="text-right">
          <button type="button"
                  class="btn btn-sm btn-primary"
                  :disabled="invoice.disabled(`submit`)"
                  v-on:click="invoice.click(`submit`)">Submit</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
h3 {
  opacity: 0.5;
}
.date-picker {
  width: 100%;
}

span {
  font-size: 0.9em;
}

span.multi {
  font-size: 0.8em;
  opacity: 0.7;
  font-style: italic;
}

small {
  padding-left: 0.7em;
  margin-top: 0.1em;
}

div.menu {
  height: 2.5em;
}

div.controls {
  width: 15em;
}

span.date {
  font-size: 0.7em;
}

button {
  width: 80px;
}

div.modal-body {
  height: calc(100vh - 12em);
}

div.accounts div.form-group {
  margin-bottom: 0.5em;
}

p.modal-title i,
p.modal-title span {
  opacity: 0.7;
}

table.accounts th:nth-child(1) {
  width: 8%;
}

table.accounts th:nth-child(2) {
  width: 50%;
}

table.accounts th:nth-last-child(2) {
  width: 12%;
}

table.accounts th:nth-last-child(1) {
  width: 12%;
}

table.schedule th:nth-child(1) {
  width: 25%;
}

table.schedule th:nth-child(2) {
  width: 15%;
}

table.schedule th:nth-last-child(2) {
  width: 12%;
}

table.schedule th:nth-last-child(1) {
  width: 12%;
}

span.total {
  font-weight: 600;
}

span.title {
  font-size: 1em !important;
}

p.schedule {
  font-weight: 500;
}

table.line-items th:nth-child(1) {
  width: 40%;
}

table.line-items th:nth-last-child(2) {
  width: 40%;
}

table th:nth-last-child(1) {
  width: 2em;
}

</style>

<script>
import { checkbox, loading, scrollArea } from "../../../../../component";
import { Api, lib, Record } from "../../../../../factory";
import { moment, numeral } from "../../../../../npm";
import { alert, session } from "../../../../../service";
import { showPeriodLength, calculateLineItemAmount } from "../../../../../service/invoice_period";
import RebatedTransactions from "./rebated-transactions.vue";

import DatePicker from "vue2-datepicker";
import UsFederalHolidays from '@18f/us-federal-holidays';
import { isHoliday as isNyseHoliday } from "nyse-holidays";
import "vue2-datepicker/index.css";

export default {
  get components() {
    return {
      checkbox,
      DatePicker,
      loading,
      scrollArea,
      RebatedTransactions
    };
  },
  props: {
    data: {
      type: Object,
      required: true,
      validator: (o) => typeof o.contract == `object` &&
        typeof o.methods == `object` &&
        typeof o.methods.success == `function`,
    },
  },
  data() {
    return {
      invoice: ``,
    };
  },
  watch: {
    "invoice.period": {
      handler() {
        var { period } = this.invoice;

        if (period.start)
          this.invoice.fees();
      },
      deep: true,
    },
  },
  created() {
    this.init();
  },
  methods: {
    value(v, f = `$0,0.00`) { return numeral(v).format(f) },
    init() {
      const state = session.get(`state`);
      var { contract, methods } = this.data;
      var invoice = {
        _account(o) {
          var a = this._data.accounts.find((a) => a.number == o.number);
          var { name, number, fundedDate } = a;
          var { name, number, fundedDate } = a;
          var { name: household } = this._data.households.find((h) => h._id == a.householdId);
          var { name: schedule } = contract.schedules[o.schedule];
          var billing; 
          if (o.billing == -1)
            billing = this._text.SELF
          else if (o.billing == 99)
            billing = this._text.BTI
          else
            billing = contract.accounts.find(ac => ac._id == o.billing).number 
          var value = 0;
          var fee = 0;
          var owing = 0;
          var due = 0;
          var proratedDays = fundedDate && !this.isAdvance ? this.updateProratedDays(fundedDate) : null;
          return {
            billing,
            due,
            fee,
            household,
            name,
            number,
            owing,
            value,
            schedule,
            proratedDays,
            fundedDate,
          };
        },
        _accounts: ``,
        group: ``,
        _add(type) {
          switch (type) {
            case `line-item`:
              var [account] = this.accounts(`line-items`);
              var value = numeral(0).format(`0.00`);
              this.line.items.push({ account, value, comment: `` });
              break;
            default:
              throw new Error(`Invalid add type, ${type}!`);
          }
        },
        display_date(d) {
          var f = `MMM D, YYYY`;
          return moment(d).format(f);
        },
        _date(data) {
          var format = `MMM D, YYYY`;

          var { dates } = this._data;
          switch (data) {
            case `format`:
              return format;
            case `start`:
              return this.isAdvance
                ? this._date(moment().format(`L`))
                : this._date(dates[0]);
            case `end`:
              return contract.status == 2
                ? this.isAdvance
                  ? moment().add(3, `months`).format(`L`)
                  : this._date(dates[dates.length - 1])
                : moment(contract._updated).format(`L`);
            default:
              return (typeof data == 'string' && data.includes(`/`))
                ? moment(data, `L`).format(format)
                : moment(data, format).format(`L`);
          }
        },
        _data: { accounts: ``, dates: ``, fees: ``, households: `` },
        async _dates() {
          await this._update();
          var { period } = this;
          period.start = this._date(`start`);
          period.end = this._date(`end`);
          period.range = [
            moment(period.start, "L").toDate(),
            moment(period.end, "L").toDate(),
          ];
        },
        _fees(d) {
          this._data.fees = d;
          this._accounts.forEach((a) => {
            var account = d.accounts.find((o) => o.number == a.number);
            a.value = account.value;
            a.due = account.due;
            a.fee = account.fee;
            a.owing = account.owing;
            a.grandTotal = account.grandTotal;
            
            // Update proratedDays
            if (a.fundedDate)
              a.proratedDays = this.updateProratedDays(a.fundedDate);
          });
          this.schedules.forEach((s, i) => {
            var schedule = d.schedules[i];
            s.minimum = schedule.minimum;
            s.splits = [...schedule.splits];
            s.fees = [...schedule.fees];
            s.owing = schedule.owing;
            s.total = schedule.total;
            // if thresholdBilling is enabled the `breaks` and `rates`
            // were reduced on the backend and we'll use those new ones
            // not the default one we got from contract.schedules.[rates/breaks]
            if (this.thresholdBilling) {
                s.breaks = schedule.breaks
                s.rates  = schedule.rates
            }
          });
        },
        _init: (...args) => this.$set(...args),
        async _load() {
          try {
            if (this._pending)
              return;
            this._pending = true;
            var r = await Api.get(`records?contractId=${contract._id}&_dates=true`);
            var { data: dates } = r;
            this._data.dates = dates;
            r = await Promise.all(contract.accounts.map((o) => Api.get(`accounts?number=${encodeURIComponent(o.number)}`)));
            var accounts = r.map((r) => r.data[0]);
            this._data.accounts = accounts;
            var householdIds = [
              ...accounts.reduce((s, a) => s.add(a.householdId), new Set()),
            ];
            var households = [
              ...(await Promise.all(householdIds.map((id) => Api.get(`households/${id}`)))),
            ].map((o) => o.data);
            this._data.households = households;
            var [household] = households;
            const [group] = (await Api.get(`groups?householdIds=${household._id}`)).data;
            this.group = group;
            this.ref = household.name;
            this._accounts = contract.accounts.map((o) => this._account(o));
            this._schedules = contract.schedules.map((o) => this._schedule(o));
            var [date] = dates.slice().reverse();
            var { period } = this;
            period.advance.latest = new Date(); // adding inital value for `latest`
            period.arrears.latest = new Date(); // adding inital value for `latest`
            this.lineItems = contract.lineItems;
            this.ready = true;
            this.quarterBill = state.firm.features?.quarterBill ?? false;
            this.monthlyBill = state.firm.features?.monthlyBill ?? false 
            this.thresholdBilling = state.firm.features.thresholdBilling ?? false 
            this.noBlendBilling = state.firm.features.noBlendBilling ?? false
          }
          catch (e) {
            console.error(e);
            alert.error(e.message);
          }
          finally {
            this._pending = false;
          }
        },
        lineItems: ``,
        _pending: ``,
        _period: () => {
          const quarterStartDate = moment().subtract(1, "quarter").startOf("quarter").toDate();
          const quarterEndDate = moment().subtract(1, "quarter").endOf("quarter").toDate();
          const oneDayAgo = moment().subtract(1, 'day').toDate();
          const currentQuarterStart = moment().startOf("quarter").toDate()
          const currentQuarterEnd = moment().endOf("quarter").toDate()
          const now = new Date()

          return {
            start: now,
            end: now,
            advance: {
              adb: [quarterStartDate, quarterEndDate],
              latest: oneDayAgo
            },
            arrears: {
              adb: [quarterStartDate, quarterEndDate],
              latest: oneDayAgo
            },
            range: [ currentQuarterStart, currentQuarterEnd],
          }
        },
        _remove(type, d) {
          switch (type) {
            case `line-item`:
              this.line.items.splice(d, 1);
              break;
            default:
              throw new Error(`Invalid remove type, ${type}!`);
          }
        },
        _schedule(o) {
          var fees = [];
          var owing = 0;
          var total = { fees: 0, splits: 0 };
          var splits = [];
          return { ...o, fees, owing, splits, total };
        },
        _schedules: ``,
        _show() {
          var { all, numbers } = this.show;
          return { ...all, numbers };
        },
        async _submit() {
          if (this._pending)
            return;
          this._pending = true;
          try {
            var { date, line, ref, groupSchedulesValues } = this;
            var { items: lineItems } = line;
            var show = this._show();
            var { _id: contractId } = contract;
            var refund = false;

            var d = {
              contractId,
              date: moment(date, "MMM D, YYYY").format(`L`),
              fees: this.calculatedFees,
              lineItems,
              ref,
              refund,
              show,
              groupSchedulesValues,
            };
            var r = await Api.post(`invoices`, d);
            methods.success(r.data);
          }
          catch (e) {
            console.error(e);
            alert.error(e.message);
          }
          finally {
            this._pending = false;
          }
        },
        _text: { NONE: ``, SELF: ``, BTI: `` },
        _type() {
          return this.options(`type`)[0];
        },
        _update: () => new Promise((r) => this.$nextTick(r)),
        accounts(type) {
          var account = (o) => {
            var len = Math.floor(o.number.length / 2);
            var number = this.show.numbers
              ? [...new Array(len).fill(`*`), o.number.slice(len)].join(``)
              : o.number;
            return { ...o, number, safeNumber: o.number };
          };
          switch (type) {
            case `line-items`:
              return [
                `All Accounts`,
                ...this._accounts.map((o) => `(${o.number}) ${o.name}`),
              ];
            case `due`:
              return this._accounts
                .filter((o) => o.due || this.show.all.due || this.hasLineItem(o) || o.rebatedTransactions?.length)
                .map((o) => account(o));
            case `grandtotal`:
              return this._accounts
                .map((o) => account(o));
            case `fees`:
              var accounts = this._accounts.slice().map((o) => account(o));
              var { exceptions = [] } = this._data.fees;
              exceptions.forEach((e) => {
                var i = accounts.findIndex((a) => a.safeNumber == e.account);
                if (i < 0)
                  return;
                var number = this._text.NONE;
                var type = lib.string.capitalize(e.type);
                var ticker = e.ticker
                  ? contract.exceptions.find((o) => o.type == `position` && o.ticker.symbol == e.ticker)?.ticker
                  : ``;
                var exception = ticker
                  ? `Ticker: ${ticker.symbol} (${ticker.name})`
                  : type;
                var schedule = contract.schedules[e.schedule].name;
                var o = {
                  ...e,
                  name: exception,
                  number,
                  schedule,
                };
                accounts.splice(i + 1, 0, o);
              });
              const displayedAccounts = accounts.filter((o) => { 
                if (this.show.all.fees) return true  // show all accounts and exceptions if this is true
                if (o.billing && o.household) return true // keep the top level account showing even if fee = 0
                if (o.fee) return true  // finally check if the the fee > 0 then include it
                return false // everything else
               });
              return displayedAccounts
          }
        },
        get isAdvance() {
          return this.type == this.options(`type`)[0];
        },
        get isLatest() {
          return contract.billing.values.toLowerCase() == 'latest'
        },
        get hasTransactions() {
          return this.transactions && this.transactions.length > 0
        },
        blur(type, d, e) {
          switch (type) {
            case `line-item`:
              switch (e) {
                case `value`:
                  d[e] = numeral(d[e]).format(`0,0.00`);
                  break;
                default:
                  throw new Error(`Invalid blur type key, ${e}!`);
              }
              break;
            default:
              throw new Error(`Invalid blur type, ${type}!`);
          }
        },
        click(type, d, e) {
          switch (type) {
            case `add`:
            case `remove`:
              switch (d) {
                case `line-item`:
                  return type == `add`
                    ? this._add(`line-item`)
                    : this._remove(`line-item`, e);
                default:
                  throw new Error(`Invalid click ${type} data ${d}!`);
              }
            case `submit`:
              return this._submit();
            default:
              throw new Error(`Invalid click type, ${type}!`);
          }
        },
        change(type, d, e) {
          switch (type) {
            case `type`:
              return this._dates();
            default:
              throw new Error(`Invalid change type, ${type}!`);
          }
        },
        date: ``,
        disabled(type) {
          switch (type) {
            case `submit`:
              return this._pending;
            case `type`:
              return contract.hasOwnProperty(`billing`);
            default:
              throw new Error(`Invalid disabled type, ${type}!`);
          }
        },
        accountsChanges: ``,
        schedulesChanges: ``,
        exceptionsChanges: ``,
        lastInvoice: ``,
        adjustmentContract: ``,
        groupSchedulesValues: ``,
        transactions: [],
        async lastInvoiceFees(adjustments) {
          if (adjustments?.lastInvoice) {
            this.lastInvoice = adjustments?.lastInvoice;
            this.adjustmentContract = (await Api.get(`contracts/${this.lastInvoice.contractId}`)).data;
            const res = await Promise.all(this.adjustmentContract.accounts.map((o) => Api.get(`accounts?number=${o.number}`)));
            const accounts = res.map((r) => r.data[0]);
            this.adjustmentContract.accounts.forEach((account) => {
              account.name =
                accounts.find((acc) => acc.number == account.number)?.name ??
                "";
            });
          }
          if (adjustments?.accountsChanges?.length)
            this.accountsChanges = adjustments?.accountsChanges;
          if (adjustments?.schedulesChanges?.length)
            this.schedulesChanges = adjustments?.schedulesChanges;
          if (adjustments?.exceptionsChanges?.length)
            this.exceptionsChanges = adjustments?.exceptionsChanges;
        },
        async getCurrentFees() {
          var cashflowAdjustment = state.firm.features.cashflowAdjustment 
          var query = {
            _id: contract._id,
            type: this.type,
            date: this._date(this.date),
            start: this._date(this.period.range[0]),
            end: this._date(this.period.range[1]),
            advance: {
              start: this._date(this.period.advance.adb[0]),
              end: this._date(this.period.advance.adb[1]),
              latest: this._date(this.period.advance.latest)
            },
            arrears: {
              start:this._date(this.period.arrears.adb[0]),
              end: this._date(this.period.arrears.adb[1]),
              latest: this._date(this.period.arrears.latest) 
            },
            quarterBill: this.quarterBill,
            monthlyBill: this.monthlyBill,
            thresholdBilling: this.thresholdBilling,
            cashflowAdjustment: cashflowAdjustment ? cashflowAdjustment : false,
            noBlendBilling: this.noBlendBilling 
          }
          var r = await Api.post(`contracts/fees`, { query: query });
          this.calculatedFees = r.data;
          return r.data;
        },
        calculatedFees: ``,

        async fees() {
          try {
            if (this._pending)
              return;
            this._pending = true;
            const currentFees = await this.getCurrentFees();
            this.groupSchedulesValues = currentFees.groupSchedulesValues;
            this.transactions = currentFees.accounts.reduce((acc, account) => {
              if (account.rebatedTransactions?.length) {

                return acc.concat(account.rebatedTransactions);
              }

              return acc;
            }, [])
            await this.lastInvoiceFees(currentFees.adjustments);
            this._fees(currentFees);
          }
          catch (e) {
            console.error(e);
            alert.error(e.message);
          }
          finally {
            this._pending = false;
          }
        },
        focus(type, d, e) {
          switch (type) {
            case `line-item`:
              switch (e) {
                case `value`:
                  d[e] = numeral(d[e]).value();
                  break;
                default:
                  throw new Error(`Invalid focus type key, ${e}`);
              }
              break;
            default:
              throw new Error(`Invalid focus type, ${type}!`);
          }
        },
        init() {
          this.date = new Date();
          this.period = this._period();
          this.ref = ``;
          this.show = {
            all: { due: false, fees: false, tiers: false },
            numbers: true,
          };
          this.type = contract.billing ? contract.billing.type : this._type();
          this.line = { items: [] };
          this._text = { NONE: ``, SELF: `Self`, BTI: "Bill To Invoice" };
          this._load();
          return this;
        },
        options(type, data) {
          var format = this._date(`format`);
          var { dates } = this._data;
          var { period } = this;
          switch (type) {
            case `date`:
              return { format };
            case `period`:
              switch (data) {
                case `start`:
                  return {
                    format,
                    minDate: this.isAdvance ? `` : this._date(dates[0]),
                    maxDate: this._date(period.end),
                  };
                case `end`:
                  return {
                    format,
                    minDate: this._date(period.start),
                    maxDate: contract.status == 2
                      ? this.isAdvance
                        ? ``
                        : this._date(dates[dates.length - 1])
                      : this._date(moment(contract._updated).format(`L`)),
                  };
                case `values`:
                  return {
                    format,
                    dates: dates.map((d) => this._date(d)),
                  };
                default:
                  throw new Error(`Invalid options type ${type}!`);
              }
            case `type`:
              return [`advance`, `arrears`];
            default:
              throw new Error(`Invalid options type, ${type}!`);
          }
        },
        period: {
          start: ``, 
          end: ``,
          advance: { adb: [], latest: null },
          arrears: { adb:[], latest: null }, 
          range: [],
        },
        lineItemPeriod: ``,
        ready: ``,
        ref: ``,
        quarterBill: ``,
        monthlyBill: false,
        thresholdBilling: false,
        noBlendBilling: false,
        get schedules() {
          return this._schedules;
        },
        show: ``,
        getGroupRate(d, e) {
          function getRate(yearFee, split, period) {
            return (yearFee / (split * period)) * 100;
          }
          const a = moment(this.period.end);
          const b = moment(this.period.start);
          const days = a.diff(b, "days");
          const daysInYear = b.isLeapYear() ? 366 : 365;
          const split = d.splits[e];
          const fee = d.fees[e];
          const period = days / daysInYear;
          const hasRateApplied = this.groupSchedulesValues &&
            this.groupSchedulesValues[d.name] > d.breaks[1];
          const appliedRate = hasRateApplied
            ? getRate(fee, split, period)
            : d.rates[0];
          return `${numeral(appliedRate).format(`0,0.[000]`)}%`;
        },
        /**
         * show line item if the end date 
         * of the period before the start of this line item 
         * @param {*} lineItem 
         */
        showLineItem(lineItem) {
          if (lineItem.startDate && this.period.end) {
            // if the lineItem start date is later than the specified period, skip this line item
            if (moment(lineItem.startDate).isAfter(this.period.end))
              return false
          }
          return true
        },
        hasLineItem(account) {
          if (this.lineItems?.length) {
            for (const item of this.lineItems) {
              if (item.number == "*" || item.number == account.number) {
                return true;
              }
            }
          }
          return false;
        },
        getAccountGrandTotal(account, forceZero=false) {
          if (typeof account.grandTotal != "undefined") {
            // if the grand total is < 0, we force it to be 0
            // as we'll use that negative amount later as a carryover line item
            return account.grandTotal < 0 && forceZero ? 0 : account.grandTotal;
          }
          let v = account.due;
          const accountChanges = this.accountsChanges != ""
            ? this.accountsChanges?.find((acc) => acc.number == account.safeNumber)
            : 0;
          if (accountChanges)
            v += accountChanges.adjustment;
          if (this.lineItems?.length) {
            for (const item of this.lineItems) {
              if (item.number == "*" || item.number == account.safeNumber) {
                v += calculateLineItemAmount(item, this.period)
              }
            }
          }
          return v;
        },
        text(type, d, e) {
          var value = (v, f = `$0,0.00`) => numeral(v).format(f);
          var number = (n) => this.show.numbers
            ? [
              ...new Array(Math.floor(n.length / 2)).fill(`*`),
              n.slice(Math.floor(n.length / 2)),
            ].join(``)
            : n;

          switch (type) {
            case `account`:
              if (!d[type].includes(`(`))
                return d[type];
              var [firstPart, secondPart] = d[type].split(`)`);
              var n = number(firstPart.split(`(`).pop());
              return `(${n}) ${secondPart.trim()}`;
            case `amount`:
              return value(d[type]);
            case `amount-due`:
              const amountValue = calculateLineItemAmount(d, this.period)
              return value(amountValue)
            case `schedule-name`:
              return this.adjustmentContract.schedules[d.schedule].name;
            case `account-name`:
              return this.adjustmentContract.accounts.find((acc) => acc.number == d.number).name;
            case `account-grand-total`:
              var gTotal = this.getAccountGrandTotal(d)
              return value(gTotal < 0 ? 0 : gTotal);
            case `schedule`:
              return `Schedule ${String.fromCharCode(97 + e).toUpperCase()} - ${d.name}`;
            case `tier`:
              var start = (v) => value(v == 0 ? v : v + 1, `$0,0`);
              var end = (v) => value(v, `$0,0`);
              return e < d.breaks.length - 1
                ? `${start(d.breaks[e])} - ${end(d.breaks[e + 1])}`
                : `> ${start(d.breaks[e])}`;
            case `rate`:
              return `${numeral(d.rates[e]).format(`0,0.[000]`)}%`;
            case `group-rate`:
              return this.getGroupRate(d, e);
            case `lineitem-period`:
              return showPeriodLength(d, this.period)
            case `fee`:
            case `split`:
            case `due`:
            case `grandtotal`:
            case `value`:
              var v = typeof e == `number` ? d[`${type}s`][e] : d[type];
              return type == `fee`
                ? d.owing
                  ? `(*) ${value(v)}`
                  : value(v)
                : value(v);
            case `modal`:
              return `New Invoice - Contract ${Record.id(contract)}`;
            case `total`:
              var v, key;
              switch (d) {
                case `due`:
                  v = this.accounts(`due`).reduce((v, a) => (v += a[d]), 0);
                  break;
                case `grandtotal`:
                  v = this.accounts(`grandtotal`).reduce((v, a) => (v += a[d]), 0);
                  break;
                case `account-values`:
                case `account-fees`:
                  key = d.split(`-`).pop().slice(0, -1);
                  v = this.accounts(`fees`).reduce((v, a) => (v += a[key]), 0);
                  break;
                case `account-grand-total`:
                  v = this.accounts(`grandtotal`).reduce((v, a) => (v += this.getAccountGrandTotal(a, true)), 0);
                  break;
                case `accounts-changes-change`:
                case `accounts-changes-adjustment`:
                  key = d.split(`-`).pop();
                  v = this.accountsChanges.reduce((v, a) => (v += a[key]), 0);
                  break;
                case `schedules-changes-change`:
                case `schedules-changes-adjustment`:
                  key = d.split(`-`).pop();
                  v = this.schedulesChanges.reduce((v, a) => (v += a[key]), 0);
                  break;
                case `schedule-fees`:
                case `schedule-splits`:
                  key = d.split(`-`).pop();
                  v = e.total[key];
                  break;
                default:
                  throw new Error(`Invalid text ${type} d ${d}!`);
              }
              return value(v);
            case `owing`:
              return d
                ? d.minimum
                  ? `This schedule fees fell short of the pro-rata minimum (${value(d.minimum)} by ${value(d.owing)})}`
                  : ``
                : this.accounts(`fees`).find((o) => o.owing)
                  ? `(*) Fee includes a pro-rata amount based on the schedule minimum shortfall`
                  : ``;
            default:
              throw new Error(`Invalid text type, ${type}!`);
          }
        },
        tiers(o) {
          return o.splits.filter((v) => v || this.show.all.tiers);
        },
        title(type, d) {
          switch (type) {
            case `add`:
            case `remove`:
              switch (d) {
                case `line-item`:
                  return type == `add` ? `Add Line Item` : `Remove item`;
                default:
                  throw new Error(`Invalid title ${type} data, ${d}`);
              }
            default:
              throw new Error(`Invalid title type, ${type}!`);
          }
        },
        updateProratedDays(fundedDate) {
          const startDate = moment(this.isLatest ? this.period.range[0] : this.period.arrears.adb[0])
          const endDate = moment(this.isLatest ? this.period.range[1] : this.period.arrears.adb[1])
          const fundedD = moment(fundedDate)

          return fundedD.startOf('day').isBetween(startDate, endDate)  
            ? endDate.diff(fundedD, 'days') 
            : null
        }
      };
      this.invoice = invoice.init();
    },
    disableWeekends (date) {
      const holidayOpts = { shiftSaturdayHolidays: true, shiftSundayHolidays: true };
      // check for holidays and with the excpetions of "Columbus" and "Veteran" days
      const allHolidays = UsFederalHolidays.allForYear(date.getFullYear(), holidayOpts)
      const found = allHolidays.find(h => h.date.getTime() == date.getTime())
      if (found)
        return found.name.includes("Columbus") || found.name.includes("Veterans")
          ? false : true
        
      if (isNyseHoliday(date)) return true

      const day = new Date(date).getDay()
      return day === 0 || day === 6
    },
    
  },
};
</script>
